namespace MetaGQL.Types

type ARelationship =
    | IsOne
    | IsMany
    | IsEnum

type AEntity =
    | AEntity of string * (string * AType) list

and AType =
    | ABool
    | AInt
    | ADouble
    | ABigInt
    | AString
    | AUuid
    | ADateTime
    | ADate
    | AInterval
    | ACustom of string
    | AOption of AType
    | AList of AType
    | AForeign of AEntity * string option * ARelationship

type AValue =
    | AVBool of bool
    | AVInt of int
    | AVDouble of double
    | AVBigInt of int
    | AVString of string
    | AVUuid of string
    | AVDateTime of System.DateTimeOffset
    | AVDate of System.DateTimeOffset
    | AVInterval of string
    | AVOption of AValue option
    | AVList of AValue list

type DefVar =
    | DefVar of string * AType
    | DefVarBang of string * AType
    | DefVarDefault of string * AType * string

type Value =
    | Constant of string // TODO: this needs to be made into AValue
    | LookupVar of string

// TODO: improve by reducing everything ti just 'PLookup', the cardinality is stored in AForeign.ARelationship now
type ProjectionType =
    | PLookup of string
    | PManyLookup of string * ProjectionType list
    | PObjectLookup of string * ProjectionType list
    | PEnumLookup of string

type Projection = Projection of ProjectionType list

module Projection =
    let toList (Projection xs) = xs

type OrderBy =
    | OrderByNone
    | OrderByAsc of string
    | OrderByDesc of string

type WithAggregate =
    | NoAggregate
    | WithAggregateCount of string
    | WithAggregateMax of string * string
    | WithAggregateMin of string * string
    | WithAggregateSum of string * string
    | WithAggregateStddev of string * string
    | WithAggregateVariance of string * string

type AttrLookup =
    | SimpleAttr of string
    | ComplexAttr of string list

type FilterOp =
    | OpOr of FilterOp list
    | OpAnd of FilterOp list
    | OpILike of AttrLookup * Value
    | OpIsNull of AttrLookup * bool
    | OpEq of AttrLookup * Value
    | OpNot of AttrLookup
    | OpIn of AttrLookup * Value
    | OpNotIn of AttrLookup * Value
    | OpGt of AttrLookup * Value
    | OpLt of AttrLookup * Value
    // TODO: implement more

type Limit =
    | NoLimit
    | Limit of int

type QueryMapping =
    { Entity: AEntity
      Projection: Projection
      Filter: FilterOp list
      OrderBy: OrderBy
      WithAggregate: WithAggregate
      Limit: Limit
      Offset: int option
      Alias: string option }

// used for when instantiating a Query
type QueryInput = QueryInput of (string * obj) list

type Query =
    { QueryInput: DefVar list
      QueryMapping: QueryMapping list }

type InsertOne =
    { Name: string
      Entity: AEntity
      Projection: Projection
      Assignment: (AttrLookup * Value) list }

type DeleteByPK =
    { Name: string
      PK: (AttrLookup * Value) list
      Entity: AEntity
      Projection: Projection }

type Delete =
    { Name: string
      Entity: AEntity
      Filter: FilterOp list
    }

type UpdateByPKSet =
    { Name: string
      PK: (AttrLookup * Value) list
      Entity: AEntity
      Projection: Projection
      Assignment: (AttrLookup * Value) list }

type UpdateInc =
    { Name: string
      Filter: FilterOp list
      Entity: AEntity
      Projection: Projection
      Inc: (AttrLookup * Value) list }

type MutationOp =
    | InsertOne of InsertOne
    | DeleteByPK of DeleteByPK
    | Delete of Delete
    | UpdateByPKSet of UpdateByPKSet
    | UpdateInc of UpdateInc

type Mutation =
    { MutationInput: DefVar list
      MutationMapping: MutationOp list }

module AEntity =
    let tableName (AEntity (name, _)) = name

module EntityHelper =

    let isForeignEntity = function
        | AForeign _ -> true
        | _ -> false

    //
    // returns an entity without their foreign keys (to retrieve base attributes only)
    //
    let withoutFKs (AEntity (name, attrs)) =
        AEntity (name, List.filter (snd >> isForeignEntity >> not) attrs)

    let add (AEntity (name, attrs)) (xs:(string * AType) list) =
        AEntity (name, List.append attrs xs)

module ProjectionHelper =

    let isForeignProjection = function
        | PLookup _ -> false
        | PManyLookup _ -> true
        | PObjectLookup _ -> true
        | PEnumLookup _ -> true

    let withoutFKs = List.filter (isForeignProjection >> not)

    let add (xs:ProjectionType list) (xs2:ProjectionType list) = List.append xs xs2

    let rec ofEntity (AEntity (_, xs):AEntity) : Projection =

        let ofEntityInner (attr, theType) =
            match theType with
            | ABool -> PLookup attr
            | AInt -> PLookup attr
            | ADouble -> PLookup attr
            | ABigInt -> PLookup attr
            | AString -> PLookup attr
            | AUuid -> PLookup attr
            | ADateTime -> PLookup attr
            | ADate -> PLookup attr
            | AInterval -> PLookup attr
            | ACustom _ -> PLookup attr
            | AOption _ -> PLookup attr
            | AList _ -> PLookup attr
            | AForeign (nextEntity, _, IsMany) -> PManyLookup (attr, ofEntity nextEntity |> Projection.toList)
            | AForeign (nextEntity, _, IsOne) -> PObjectLookup (attr, ofEntity nextEntity |> Projection.toList)
            | AForeign (_, _, IsEnum) -> PEnumLookup attr

        List.map ofEntityInner xs |> Projection

module AValue =

    let rec toString = function
        | AVBool x -> if x then "true" else "false"
        | AVInt x -> x.ToString ()
        | AVDouble x -> x.ToString ()
        | AVBigInt x -> x.ToString ()
        | AVString x -> x
        | AVUuid x -> x
        | AVDateTime x -> x.ToString ()
        | AVDate x -> x.ToString ()
        | AVInterval x -> x
        | AVOption mx -> match mx with
                         | Some x -> toString x
                         | None -> "null"
        | AVList xs -> List.map toString xs |> String.concat ", "
                       |> sprintf "[%s]"
