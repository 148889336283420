module Components.EditableInput

module EditableInput =

    open Feliz
    open Feliz.UseListener
    open Browser.Types

    type Props =
        { id: string
          fieldLabel: string
          value: string
          disabled: bool
          onChange: string -> HTMLElement -> unit
          extraElements : ReactElement option
        }

    let editableInput = React.functionComponent(fun(props:Props) ->
        let isFocused, setIsFocused = React.useState false
        let theInput (extraClass:string) =
            Html.input [
                prop.id props.id
                prop.className ((if props.disabled then "non-editable" else "editable") + " " + extraClass)
                prop.disabled props.disabled
                prop.valueOrDefault props.value
                prop.onFocus (fun _ -> setIsFocused true)
                prop.onBlur (fun e ->
                    if isFocused then
                        let newText = FrontendUtils.Extensions.eventValue e
                        let el = e.currentTarget :?> HTMLElement
                        props.onChange newText el
                        setIsFocused false
                    else ())
                prop.onKeyPress (key.enter, fun (e:KeyboardEvent) ->
                    printfn "----- EditableInput onKeyPress ENTER!"
                    let newText = FrontendUtils.Extensions.eventValue e
                    let el = e.currentTarget :?> HTMLElement
                    props.onChange newText el
                    el.blur()
                    e.preventDefault())
            ]
        Html.div [
            prop.className "field"
            prop.children [
                Html.label [
                    prop.className "label"
                    prop.text props.fieldLabel
                ]
                (*
                Html.div [
                    prop.className (if props.disabled then "non-editable" else "editable")
                    prop.contentEditable (not props.disabled)
                    prop.custom ("suppressContentEditableWarning", true)
                    prop.onClick (fun e ->
                        let el = e.currentTarget :?> HTMLElement
                        el.focus ())
                    prop.onPaste (fun e ->
                        e.stopPropagation ()
                        e.preventDefault ()
                        let content = e.clipboardData.getData "Text"
                        let el = e.currentTarget :?> HTMLElement
                        el.innerHTML <- content
                        ())
                    prop.onKeyPress (key.enter, fun (e:KeyboardEvent) ->
                        let newText =
                            FrontendUtils.Extensions.textContent e
                            |> FrontendUtils.Extensions.stripHtml
                        //printfn "'%s' %A isnull:%A" newText replaceNullWithEmptyString (newText = "")
                        //let adaptedText =
                        //    if replaceNullWithEmptyString && newText = "null" then " " else newText
                        //printfn "adaptedText before sending: '%s'" adaptedText
                        let el = e.currentTarget :?> HTMLElement
                        props.onChange newText el
                        el.blur()
                        e.preventDefault())
                    prop.children [
                        Html.text props.value
                    ]
                ]
                *)
                match props.extraElements with
                | None -> theInput "w-full"
                | Some extraElements ->
                    Html.div [
                        prop.className "flex flex-row items-center space-x-2"
                        prop.children [
                            theInput "w-3/4"
                            extraElements
                        ]
                    ]
            ]
        ])