module Utilities.Remoting

open Feliz
open Shared

let withException appDispatch (callF : Response<'a>) responseF errorF = async {
        try
            let! response = callF
            match response with
            | Ok res ->
                appDispatch <| Client.Types.SetJwt res.Jwt
                return responseF response appDispatch
            | Error error ->
                let errStr = error.ToString ()
                appDispatch <| Client.Types.AddPopupMessage (Html.div errStr)
                return errorF
        with error ->
            Log.developmentError error
            let errStr = error.ToString ()
            appDispatch <| Client.Types.AddPopupMessage (Html.div errStr)
            return errorF
    }

let withResultError okF (result : Result<ResponseWithJwt<'a>, ErrorType>) errorF =
    match result with
    | Ok res -> okF res.Result
    | Error err -> errorF err

let err e2 okF result appDispatch = withResultError okF result (fun err ->
    match err with
    | ErrorMessages xs ->
        appDispatch <| Client.Types.AddPopupMessage (Html.div (String.concat "," xs))
        e2
    | ErrorInvalidAccessToken ->
        appDispatch Client.Types.ClearJwt
        e2)

let errIgnoreMessages appDispatch e okF result = withResultError okF result (fun err ->
    match err with
    | ErrorMessages _xs -> e
    | ErrorInvalidAccessToken ->
        appDispatch Client.Types.ClearJwt
        e)
